<template>
  <dv-full-screen-container style="background-color: #2c3e50;position: relative">
<!--    <dv-loading v-if="showLoaing" style="color:#fff" bac>数据加载中...</dv-loading>-->
    <dv-border-box-11 v-if="dataList!=null" title="常规线显示屏" backgroundColor="#2c3e50">
      <div style="position: relative;top: 1rem">
        <span style="color: #ffffff;position: absolute;left: 0.5rem;font-size: 0.3rem;top: 0.3rem">
        当前生产线：{{
            currentShengChanXian
          }}
      </span>
        <span
            style="color: #ffffff;position: absolute;left: 2.8rem;font-size: 0.36rem;top: 0.3rem"
        >{{ nowTimeStr }}
        </span>

        <div style="position:absolute;left: 6.5rem;color: #ffffff;font-size: 0.2rem;top: 0.3rem">
          <select v-model="suoFangBiLi" style="font-size: 0.2rem" @change="selectSuoFangBiLi">
            <option v-for="(item, index) in suoFangBiLiList" :label="item" :value="item" :key="index"></option>
          </select>
          <span style="font-size: .2rem;margin-left: .1rem">
          {{ showLoaing == true ? '刷新中...' : '' }}
        </span>
        </div>

        <div style="position:absolute;right: 4.5rem;color: #ffffff;font-size: 0.3rem;top: 0.3rem">
          <span>条线选择：</span>
          <select v-model="currentShengChanXian" style="font-size: 0.3rem" @change="selectTiaoXian">
            <option v-for="(item, index) in tiXianDataList" :label="item" :value="item" :key="index"></option>
          </select>
        </div>

        <div style="position:absolute;right: 1.2rem;color: #ffffff;font-size: 0.3rem;top: 0.3rem">
          <span style="margin-left: 0.2rem">刷新频率：</span>
          <select
              v-model="currentShuaXinPinLv"
              size="mini"
              style="font-size: 0.3rem"
              @change="selectShuaXinPinLv"
          >
            <option
                v-for="(item, index) in shuaXinPinLv"
                :label="item.label"
                :value="item.id"
                :key="index"
            ></option>
          </select>
        </div>

        <button
            size="mini"
            style="position:absolute;right: 0.2rem;font-size: 0.3rem;top: .3rem"
            @click="reloadPage()"
        >刷新
        </button>

      </div>
      <div :style="suoFang" class="ji-qi-container">
        <div class="ji-qi-container-item">
          <div class="ji-qi-container-item-row" v-for="i in 12" :key="i">
            <div class="ji-qi-data-div"
                 :class="dataList.group1.leftData[i].jiQiZhuangTai!='开机'?'redCLass':'greenCLass'">
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"

              >
                <div style="width: 40%;">{{ dataList.group1.leftData[i].jiQiMa }}</div>
                <div style="width: 60%">{{ dataList.group1.leftData[i].guZhangCount }}次</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">针耗</div>
                <div style="width: 60%">{{ dataList.group1.leftData[i].haoZhenCount }}</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">{{ dataList.group1.leftData[i].jiQiZhuangTai }}</div>
                <div style="width: 60%">{{ dataList.group1.leftData[i].guZhangRemark }}</div>
              </div>
            </div>
            <div
                style="width: 49%;height: 100%;color: #ffffff"
                :class="dataList.group1.rightData[i].jiQiZhuangTai!='开机'?'redCLass':'greenCLass'"
            >
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%;">{{ dataList.group1.rightData[i].jiQiMa }}</div>
                <div style="width: 60%">{{ dataList.group1.rightData[i].guZhangCount }}次</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">针耗</div>
                <div style="width: 60%">{{ dataList.group1.rightData[i].haoZhenCount }}</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">{{ dataList.group1.rightData[i].jiQiZhuangTai }}</div>
                <div style="width: 60%">{{ dataList.group1.rightData[i].guZhangRemark }}</div>
              </div>
            </div>
          </div>
        </div>

        <!--    第二组    -->
        <div class="ji-qi-container-item">
          <div class="ji-qi-container-item-row" v-for="i in 12" :key="i">
            <div class="ji-qi-data-div"
                 :class="dataList.group2.leftData[i].jiQiZhuangTai!='开机'?'redCLass':'greenCLass'">
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%;">{{ dataList.group2.leftData[i].jiQiMa }}</div>
                <div style="width: 60%">{{ dataList.group2.leftData[i].guZhangCount }}次</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">针耗</div>
                <div style="width: 60%">{{ dataList.group2.leftData[i].haoZhenCount }}</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">{{ dataList.group2.leftData[i].jiQiZhuangTai }}</div>
                <div style="width: 60%">{{ dataList.group2.leftData[i].guZhangRemark }}</div>
              </div>
            </div>
            <div
                style="width: 49%;height: 100%;color: #ffffff"
                :class="dataList.group2.rightData[i].jiQiZhuangTai!='开机'?'redCLass':'greenCLass'"
            >
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%;">{{ dataList.group2.rightData[i].jiQiMa }}</div>
                <div style="width: 60%">{{ dataList.group2.rightData[i].guZhangCount }}次</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">针耗</div>
                <div style="width: 60%">{{ dataList.group2.rightData[i].haoZhenCount }}</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">{{ dataList.group2.rightData[i].jiQiZhuangTai }}</div>
                <div style="width: 60%">{{ dataList.group2.rightData[i].guZhangRemark }}</div>
              </div>
            </div>
          </div>
        </div>

        <!--    第三组    -->
        <div class="ji-qi-container-item">
          <div class="ji-qi-container-item-row" v-for="i in 12" :key="i">
            <div class="ji-qi-data-div"
                 :class="dataList.group3.leftData[i].jiQiZhuangTai!='开机'?'redCLass':'greenCLass'">
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%;">{{ dataList.group3.leftData[i].jiQiMa }}</div>
                <div style="width: 60%">{{ dataList.group3.leftData[i].guZhangCount }}次</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">针耗</div>
                <div style="width: 60%">{{ dataList.group3.leftData[i].haoZhenCount }}</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">{{ dataList.group3.leftData[i].jiQiZhuangTai }}</div>
                <div style="width: 60%">{{ dataList.group3.leftData[i].guZhangRemark }}</div>
              </div>
            </div>
            <div
                style="width: 49%;height: 100%;color: #ffffff"
                :class="dataList.group3.rightData[i].jiQiZhuangTai!='开机'?'redCLass':'greenCLass'"
            >
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%;">{{ dataList.group3.rightData[i].jiQiMa }}</div>
                <div style="width: 60%">{{ dataList.group3.rightData[i].guZhangCount }}次</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">针耗</div>
                <div style="width: 60%">{{ dataList.group3.rightData[i].haoZhenCount }}</div>
              </div>
              <div
                  style="display: flex;justify-content: flex-start;align-items: center;width: 100%"
              >
                <div style="width: 40%">{{ dataList.group3.rightData[i].jiQiZhuangTai }}</div>
                <div style="width: 60%">{{ dataList.group3.rightData[i].guZhangRemark }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-11>
  </dv-full-screen-container>
</template>
<script>
export default {
  data() {
    return {
      currentShengChanXian: null,
      tiXianDataList: [],
      dingShiQiShuaXin: false, //定时器启动的时候显示这个文字
      showLoaing: true,
      dingShiQi: null, //定时器
      nowTimeStr: null, //当前时间
      currentShuaXinPinLv: 0, //当前刷新频率
      shuaXinPinLv: [
        {
          id: 0,
          label: "5秒",
          value: 1000 * 5
        },
        {
          id: 1,
          label: "10秒",
          value: 1000 * 10
        },
        {
          id: 2,
          label: "20秒",
          value: 1000 * 20
        },
        {
          id: 3,
          label: "30秒",
          value: 1000 * 30
        },
        {
          id: 4,
          label: "1分钟",
          value: 1000 * 60 * 1
        },
        {
          id: 5,
          label: "2分钟",
          value: 1000 * 60 * 2
        },
        {
          id: 6,
          label: "3分钟",
          value: 1000 * 60 * 3
        },
        {
          id: 7,
          label: "4分钟",
          value: 1000 * 60 * 4
        },
        {
          id: 8,
          label: "5分钟",
          value: 1000 * 60 * 5
        }
      ],
      dataList: null, //展示数据
      suoFang: {
        transform: "scale(0.8)"
      },
      suoFangBiLi: 0.8, //当前缩放比例
      suoFangBiLiList: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
    };
  },
  created() {
    this.getChangGuiXianData(null)
    this.showDate();
    this.getShengChanXian();
    // 启动定时任务
    this.startDingShiQi();
  },
  methods: {
    //查询生产线集合
    getShengChanXian() {
      var _this = this;
      _this.showLoaing = true;
      _this.$axios.get("/changGuiXian/selectAllTiaoXian").then(res => {
        _this.tiXianDataList = res.data;
        _this.currentShengChanXian = res.data[0];
      });
    }, //获取数据
    getChangGuiXianData(tiaoXian) {
      let _this = this;
      this.showLoaing=true
      _this.$axios
          .get("/changGuiXian/getChangGuiXianData", {
            params: {tiaoXian: tiaoXian}
          })
          .then(res => {
            _this.dataList = res.data;
            _this.showLoaing = false;
            _this.dingShiQiShuaXin = false;
          });
    },
    //刷新网页
    reloadPage() {
      if (this.dingShiQi != null) {
        clearInterval(this.dingShiQi);
      }
      location.reload(true);
    },
    showDate() {
      let _this = this;
      // 创建一个 Date 对象
      setInterval(function () {
        const currentDate = new Date();
        // 获取当前日期的年、月、日
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1; // 月份从 0 开始，所以需要 +1
        month = month < 10 ? "0" + month : month; // 使用三元运算符简化代码
        let day = currentDate.getDate();
        day = day < 10 ? "0" + day : day; // 使用三元运算符简化代码
        // 获取当前时间的小时、分钟、秒
        let hours = currentDate.getHours();
        hours = hours < 10 ? "0" + hours : hours; // 使用三元运算符简化代码
        let minutes = currentDate.getMinutes();
        minutes = minutes < 10 ? "0" + minutes : minutes; // 使用三元运算符简化代码
        let seconds = currentDate.getSeconds();
        seconds = seconds < 10 ? "0" + seconds : seconds; // 使用三元运算符简化代码
        _this.nowTimeStr =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
      }, 1000);
    }, //选择了一个刷新频率
    //刷新频率
    selectShuaXinPinLv() {
      console.log(this.currentShuaXinPinLv, "[[[[");
      if (this.dingShiQi != null) {
        clearInterval(this.dingShiQi);
        console.log("已清除定时器选择了刷新频率=============");
      }
      this.startDingShiQi();
    }, //选择条线
    selectTiaoXian() {
      if (this.dingShiQi != null) {
        clearInterval(this.dingShiQi);
        console.log("已清除定时器选择了跳线=============");
      }
      this.startDingShiQi();
    },
    //启动定时器
    startDingShiQi() {
      if (this.dingShiQi != null) {
        clearInterval(this.dingShiQi);
        console.log("已清除定时器0=============");
      }
      let _this = this;
      let timePinLv = this.shuaXinPinLv[this.currentShuaXinPinLv].value;
      this.showLoaing = true
      _this.getChangGuiXianData(_this.currentShengChanXian);
      this.dingShiQi = setInterval(function () {
        _this.dingShiQiShuaXin = true;
        if (_this.dataList != null) {
          _this.getChangGuiXianData(_this.currentShengChanXian);
        }
        console.log("开始循环调用");
      }, timePinLv);
    }, //选择了缩放比例
    selectSuoFangBiLi() {
      console.log(this.suoFangBiLi, "[[[[");
      this.suoFang = {
        transform: "scale(" + this.suoFangBiLi + ")",
        transformOrigin: "top center" /* 缩放原点设置为顶部中心 */
      };
      this.$forceUpdate();
    }
  }
};
</script>
<style scoped>
.chang-gui-xian-container {
  display: flex;
}

.ji-qi-container {
  width: 100%;
  //border: 1px solid #ffffff;
  display: flex;
  justify-content: space-around;
  font-size: 0.2rem;
  position: absolute;
  margin-top: 9%;
}

.ji-qi-container-item {
  width: 30%;
  //border: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  height: 10.3rem;
  justify-content: space-around;
}

.ji-qi-container-item-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.1rem;
  height: 0.9rem;
}

.ji-qi-data-div {
  width: 49%;
  background-color: #0f8e3d;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.02rem 0.2rem;
  color: #ffffff;
}

.greenCLass {
  background-color: #0f8e3d;
}

.redCLass {
  background-color: red;
}
</style>

